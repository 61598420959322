/* *{
 height: 1000vh;   
} */
@import '../../sass/components/button';
@import '../../sass/base/animations';
@import '../../sass/components/frosted';
@import '../../sass/abstracts/variables';
@import '../../sass/layout/variables/variables';

/* container */
.container {
    display: 'flex';
    flex-flow: row wrap;
    background-color: rgba($color-primary, 1);
}
.button{
    display: flex;
}
.nav {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.navgt {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    // height: 18rem;
    //background-color: rgba($color-primary-light2, 1);
    // padding-inline: 5%;
}

.logo {
    top: 5;
    position: 'fixed';
    color: rgba(255, 157, 40, 1);
    font-size: 2rem;
    font-weight: var(--fw-700);
    text-decoration: none;
    transition: transform 0.5s;
    display: inline-block;
    font-family: 'Caveat', cursive;
    margin-right: 1rem;

    &__h3 {
        color: rgba(255, 255, 255, 1);
    }
}

.logo:hover {
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.7);
    /* transform: rotate3d(1,0.5,0.1,-30deg) scale(1.1); */
    flex-grow: 0.5rem;
    
    /* text-decoration: underline; */
}

.recordLi {
    list-style-type: none;
    display: flex;
    flex-flow: column nowrap;
    // align-items: center;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: 1rem;
}

@media (min-width: 860px) {
    .recordLi {
        list-style-type: none;
        display: flex;
        flex-flow: row wrap;
        // align-items: center;
        align-items: flex-end;
    }
}

.navShow {
    margin-bottom: 2rem;
}
.navShowList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 10;
}

.navHideList {
    margin-bottom: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 10;
    align-items: center;
}

.falist {
    scale: 0.5;
    // scale: 1.0;
    // margin-bottom: 5rem;
    visibility: visible;
    top: "";
    color: rgba($text-color, 0.8);
    // color: rgba($color-secondary-dark, 1);
    transition: 0.1s;
    margin-right: 2rem;
    cursor: pointer;
    padding: 0.5rem;
    
    // Frosted Container:before
    &:before {
        background: inherit;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 200rem rgba($font-light, 0.5);
        filter: blur(1.2rem);
        // Allow contents to be seen & hovered above the glass
        z-index: -1;
    }

    &:hover {
        scale: 0.55;
        // Menu native effects
        color: $text-color;
        // color: rgba($color-secondary-light, 1);
        transform: translateY(0.5rem);
        box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba($font-light, 0.6);

        // Frosted when hovered
        
        border-radius: 1.6rem;
        background: linear-gradient(to bottom right, $font-light, $font, $font-dark, 0.7);
        //box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(1.5rem);
        -webkit-backdrop-filter: blur(1.5rem);
        border: 1px solid rgba($font-dark, 0.803);
        position: relative;
        background: inherit;
        overflow: hidden;
        // Allow contents to be seen & hovered above the glass
        z-index: 1;
    }

    &:active {
        color: rgba($color-secondary-dark, 1);
        scale: 0.5;
        box-shadow: 0 0 0 0 ;
        //transform: translateY(0rem);
    }
}

@media (max-width: 400px) {
    .falist {
        scale: 1.0;

        &:hover {
            scale: 1.1;
        }
        &:active {
            scale: 1.0;
        }
    }
}

.falist-0 {
    visibility: hidden;
}

.fa1list-1 {
    visibility: visible;
}

.imcross {
    scale: 0.5;
    padding: 0.5rem;
    // margin-bottom: 0.6rem;
    visibility: visible;
    top: "";
    transition: 0.1s;
    margin-right: 2.2rem;
    color: rgba($text-color, 0.8);
    // color: rgba($color-secondary-dark, 1);
    cursor: pointer;

    &:hover {
        color: $text-color;
        // color: rgba($color-secondary-light, 1);
        scale: 0.55;
        transform: translateY(0.1rem);
        //box-shadow: 0.6rem 0.6rem 0.6rem 0.6rem rgba($color-primary-dark, 0.8);
        box-shadow: 0.5rem 0.5rem 0.5rem rgba($font-light, 0.7);
    }
    &:active {
        color: rgba($color-secondary-dark, 1);
        scale: 0.5;
        transform: translateY(0);
        box-shadow: 0 0 0 0;
    }
}

@media (max-width: 400px) {
    .imcross {
        scale: 1.0;
    
        &:hover {
            scale: 1.1;
        }

        &:active {
            scale: 1.0;
        }
    }
}

.ls {
    // margin-top: -9rem;
    // height: 0vh;
    // list-style-type: none;
    // display: flex;
    // justify-content: flex-end;
    // gap: 60px;
    // padding-inline: 20%;
}

.lswrap {
    margin-left: 3rem;
    //margin-top: 1rem;
    margin-right: 1rem;
    list-style-type: none;
    display: flex;
    flex-flow: column;
    grid-template-rows: repeat(6, 15%);
    gap: 10px;
}

.records {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 435px) {
    .records {
        position: relative;
        margin: 0 auto;
        padding: 0 2rem;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

.lk {
    // box-shadow: 0.1rem 0.1rem 0.25rem 0.1rem rgba(255,255,255, 0.3);

    border: 1px solid white;
   width: 92px;
    text-align: left;
    margin-right: 1rem;
    // width: 8rem;
    padding: 0.5rem 0.5rem; 
    margin-bottom: 0.5rem;
    border-radius: 1rem;
    color: rgba($font-light, .8);
    background: transparent;
    font-size: 1rem;
    font-weight: 400;
    font-family: sans-serif;
    // padding-block: 10px 0;
    text-decoration: none;
    transition: 0.5s;
    display: inline-block;
    cursor: pointer;
    transition: all .5s ease-in-out;

    &:hover {
        text-decoration: none;
        transform: translateY(1px);
        color: rgba($font-light, 1);
        flex-grow: 5px;
        
    }
    &:active {
        color: rgba($color-white, 1);
        background-color: rgba($color-primary-dark, 1);
        transform: translate(0rem);
        box-shadow: 0 0 0 0;
    }
}

.contactus {
    box-shadow: 0 0.5rem 0.5rem 0.5rem rgba($color-primary, 0.6);
    //color: rgba($color-secondary-dark, 1);
    font-size: 1.6rem;
    font-weight: 600;
    //background-color: rgba($color-primary-dark, 1);
    text-decoration: none;
    border-top: 0px solid transparent;
    border: 10px 10px 10px 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    //padding: 10px 8px;
    transition: 1s;
    display: inline-block;

    &:hover {
        box-shadow: 0 0 2rem 0 rgba($font-light, 0.7), 0 0 3rem 0 rgba($color-gold, 1);
        scale: 1.02;
        transform: translateY(0.5rem);
        //color: rgba($footer-light, 1);
        background-color: rgba($font-light, 0.7);
        /* text-decoration: none; */
        border-top: 0px solid transparent;
        //border: 10px 10px 10px 10px;
        //border-top-right-radius: 1rem;
        //border-bottom-left-radius: 1rem;
        padding: 1rem 0.8rem;
        /* transform: rotate3d(1, 0.5, 0.1, -30deg) scale(1.2); */
        flex-grow: 0.5rem;
    }
    &:active {
        color: rgba($font-light, 1);
        background-color: rgba($color-primary-light, 1);
        scale: 0.9;
        transform: translateY(0rem);
        box-shadow: 0 0 0 0;
    }
}

.themecolors {
    background-color: rgba(31, 78, 89, 1);
    background-color: rgba(126, 181, 185, 1);
    background-color: rgba(238, 234, 229, 1);
    background-color: rgba(255, 157, 40, 1);
    background-color: rgba(234, 107, 31, 1);
}

