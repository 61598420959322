// Frosted Glass Effect
// To be applied to a <div>
// Usage 
//<div className="frosted">
//  <p className="frosted__container">
@import '../abstracts/variables';

.frosted {
    // body
    background: url();
    background-size: cover;
    background-attachment: fixed;

    &__children {
        cursor: pointer;
        border-radius: 1rem;
        background: linear-gradient(to bottom right, $font-light, $font, $font-dark, $font, $font-light, $font, $font-dark, 1);
        backdrop-filter: blur(1.5rem);
        -webkit-backdrop-filter: blur(1.5rem);
        // border: 1px solid rgba($font-dark, 0.803);
        position: relative;
        //background: inherit;
        overflow: hidden;
        // Allow contents to be seen & hovered above the glass
        z-index: 1;

        // Frosted Container:before
        &:before {
            background: inherit;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            //box-shadow: inset 0 0 2500rem rgba($font-light, 0.5);
            filter: blur(1.2rem);
            // Allow contents to be seen & hovered above the glass
            z-index: -1;
        }
    }
}