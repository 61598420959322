.age-container {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
}

// .age-container .age-subcontainer {
//     position: absolute;
//     margin-top: 0.5rem;
// }

#face-image {
    max-width: 350px;
    position: relative;
    /* width: 70vw; */
    cursor: pointer;
}

@media (max-width: 37.5em) {
    #face-image {
        width: 50vw;
    }
}

#age-number {
    color: #c5e60c;
    font-size: 20px;
    display: flex;
    justify-content: center;
}

@media (max-width: 37.5em) {
    #age-number {
        max-width: 38rem;
    }
}

.age-image {
    min-width: 350px;
    max-width: 500px;
    position: relative;
    transition: all .5s ease-out;
    
    &:hover {
        transform: translateY(-2px);
        // box-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.3);
    }
    &:active {
        transform: translateY(0);
        // box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
    }
}

.saveBtn {
    // display: flex;
    // align-items: flex-end;
    
    
    &__p {
        // box-shadow: 0.025rem 0.025rem 0.025rem 0.025rem #93e9fa,
        //     0.025rem 0.025rem 0.025rem #ffffff;
        border: 1px solid rgba(255,255,255,0.7);
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);

        border-radius: 0.5rem;
        -moz-osx-font-smoothing: grayscale;
        backface-visibility: hidden;
        font-size: calc(1rem);
        // background-color: #a463f2;
        background: transparent;
        color: #fff;
        min-width: 5rem;
        max-width: 6rem;
        text-decoration: none;
        display: inline-block;
        text-transform: none;
        overflow: visible;
        font-family: inherit;
        line-height: 1.15;
        // margin-left: 1rem;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    
        &:hover {
            transform: translateY(1px);

            // box-shadow: 0.3rem 0.3rem 1rem #f6f8f8, -2px -2px 1rem #eefa43;
        }
    
        &:active {
            transform: translateY(0);
            box-shadow: 0rem 0rem 0rem 0rem #e9ec13,
                0rem 0rem 0rem #ffffff;
        }
    }
}

.modal-age {
    width: 50%;
    max-width: 200px;
    opacity: 0;
    position: absolute;
    left: 65%;
    top: 91%;

    &--inner {
        border: 2px solid white;
        background-color: rgb(2, 255, 65);
        height: 4rem;
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        border-radius: 10px;
    }
}
