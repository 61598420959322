// Grid

// mixin for clearfix
@mixin clearfix {
    // append a pseudo element to clear floats
    &::after {
        content: ""; // Clear content
        display: table; 
        clear: both; // Clear float: left && right
    }
}

// Grid
$grid-width: 142rem;
$gutter-vertical: 5rem;
$gutter-horizontal: 3.75rem;

.row {
    max-width: $grid-width;
    margin: 0 auto; // CSS auto-calc left & right margin

    /*
    &:not(:last-child) {
        margin-bottom: $gutter-vertical;
    }
    */

    // @include clearfix;

    // Columns
    .col-1-of-2 {
        width: calc((100% - #{$gutter-horizontal}) /2);
    }

    .col-1-of-3 {
        width: calc((100% - 2* #{$gutter-horizontal}) / 3);
    }

    .col-1-of-4 {
        width: calc((100% - 3* #{$gutter-horizontal}) / 4);
    }
}

.saveBtn {
    // display: flex;
    // align-items: flex-end;
        
    &__p {
        // box-shadow: 0.025rem 0.025rem 0.025rem 0.025rem #93e9fa,
        //     0.025rem 0.025rem 0.025rem #ffffff;
        border: 1px solid rgba(255,255,255,0.7);
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);

        border-radius: 0.5rem;
        -moz-osx-font-smoothing: grayscale;
        backface-visibility: hidden;
        font-size: calc(1rem);
        // background-color: #a463f2;
        background: transparent;
        color: #fff;
        min-width: 5rem;
        max-width: 6rem;
        text-decoration: none;
        display: inline-block;
        text-transform: none;
        overflow: visible;
        font-family: inherit;
        line-height: 1.15;
        // margin-left: 1rem;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    
        &:hover {
            transform: translateY(1px);

            // box-shadow: 0.3rem 0.3rem 1rem #f6f8f8, -2px -2px 1rem #eefa43;
        }
    
        &:active {
            transform: translateY(0);
            box-shadow: 0rem 0rem 0rem 0rem #e9ec13,
                0rem 0rem 0rem #ffffff;
        }
    }
}

.color-container {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 5rem;
    margin-left: 3rem;
    max-width: 100%;
    max-height: 100%;
    max-width: 142rem;

    .modal-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
    }

    .color-table {
        // margin-top: 2rem;
    }
}

.color-image__modal-container {
    position: relative;
}

.color-image-box {
    // margin: 2rem 1rem;
    padding-inline: 1% 1%;
    transition: all .2s ease-out;
}

.color-image {
    max-width: 350px;
    // max-width: 100%;
    // min-width: 10rem;
    // max-height: 100%;
    // min-height: 10rem;
    cursor: pointer;
    transition: 0.5s ease-in-out;

    &:hover{
        transform: translate(-1px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    }
}

.modal-color {
    width: 50%;
    max-width: 200px;
    opacity: 0;
    position: absolute;
    left: 65%;
    top: 90%;

    &--inner {
        border: 2px solid white;
        background-color: rgb(2, 255, 65);
        height: 4rem;
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        border-radius: 10px;
    }
}

.color-container {
    display: flex;
    flex-flow: row nowrap;
    // margin: 5rem auto;
    padding: 1rem;
    margin: 0rem auto;
    max-width: 100%;
    max-height: 100%;
}

@media (max-width: 750px) {
    .color-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }
}