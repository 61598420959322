@import '../../sass/layout/variables/variables';

// Grid
$grid-width: 142rem;
$gutter-vertical: 5rem;
$gutter-horizontal: 3.75rem;

.row {
    max-width: $grid-width;
    margin: 0 auto; // CSS auto-calc left & right margin
    &:not(:last-child) {
        // margin-bottom: $gutter-vertical;
    }

    // Columns
    .col-1-of-4 {
        width: calc((100% - 3* #{$gutter-horizontal}) / 4);
    }

    .col-1-of-5 {
        width: calc((100% - 4* #{$gutter-horizontal}) / 5);
    }

    .col-2-of-4 {
        // 2 * .col-1-of-4 + #{$gutter-horizontal}
        width: calc(2*((100% - 3* #{$gutter-horizontal})/4) + #{$gutter-horizontal});
    }

    .col-3-of-4 {
        // 3 * .col-1-of-4 + 2*#{$gutter-horizontal}
        
        width: calc(3*((100% - 3* #{$gutter-horizontal})/4) + 2*#{$gutter-horizontal});
    }

    .col-4-of-5 {
        width: calc(4*((100% - 4* #{$gutter-horizontal})/5) + 4*#{$gutter-horizontal});;
    }
}

.container {
    position: relative;
    margin: 0 auto;
    // padding: 0 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.links {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}

.link-form {
    &__paragraph {
        font-size: 1.5rem;

        color: rgba($text-color, 0.8);
    }
}

.link-container {
    border: 1px solid rgba(255,255,255, 0.8);
    
    box-shadow: 0.025rem 0.025rem 0.025rem 0.025rem #93e9fa,
            0.025rem 0.025rem 0.025rem #ffffff;
    font-size: 1.25rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
    border-radius: 0.5rem;

    background-position: 0 0, 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    min-height: 10rem;
    max-height: 12rem;
    height: 100%;
    position:relative;
    transition: all 1s ease-in-out;

    &:hover {
        box-shadow: 0.3rem 0.3rem 1rem #f6f8f8, -2px -2px 1rem #eefa43;
    }

    &__input {
        min-width: 30vw;
        width: 88%;
        overflow-x: scroll;
        display: inline-block;
        box-sizing: border-box;
        margin-right: auto;
        margin-left: auto;
        font-size: 1.25rem;
        padding: 0.5rem;
        justify-content: center;
    }
}

.buttons-box {
    display: flex;
    align-items: flex-end;

    .buttons {
        cursor: pointer;
        max-width: 6rem;
        min-width: 5rem;
    
        &__btn {
            border: 1px solid aqua;
            margin-bottom: 0.55rem;
            border-radius: 0.5rem;
            -moz-osx-font-smoothing: grayscale;
            backface-visibility: hidden;
            font-size: calc(1rem);
            // background-color: #a463f2;
            background: transparent;
            color: rgba(#ffffff, 0.8);
            min-width: 5rem;
            max-width: 6rem;
            text-decoration: none;
            display: inline-block;
            text-transform: none;
            overflow: visible;
            font-family: inherit;
            line-height: 1.15;
            margin-left: 1rem;
            cursor: pointer;
            transition: 0.5s ease-in-out;
    
            &:hover {
                // box-shadow: 0.3rem 0.3rem 1rem #f6f8f8, -2px -2px 1rem #eefa43;
                // transform: translateY(-1px);
            }
    
            &:active {
                transform: translateY(0);
                box-shadow: 0rem 0rem 0rem #e9ec13,
                0px 0px 0rem #ffffff;
            }
        }
    }
}
