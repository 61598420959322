.face-recongition {
    margin-right: auto;
    margin-left: auto;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;

    .container {
        position: absolute;
        margin-top: 2rem;

        .image-box {
            transition: all .2s ease-out;
            padding-inline: 15% 15%;
            position: relative;

            &:hover {
                transform: translateY(-1px);
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
            }

            #face-image {
                max-width: 350px;
                // margin-top: 1vh;
                // margin-bottom: 2vh;
                // max-width: 100%;
                // min-width: 50%;
                // max-height: 100%;
                // min-height: 50%;
            }
        }

    }
}

.bounding-box {
    position: absolute;
    box-shadow: 0 0 0 3px #069cfa inset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
}

.celebrity-container {
    display: flex;
}

.celebrity-name {
    border-radius: 2rem;
    color: #1738f1;
    margin-top: -5rem;
    width: 5rem;
    min-width: 10rem;
    width: 100%;
    min-height: 3rem;
    height: 13%;
    font-size: 2rem;
    opacity: 1;
}

.invisible {
    opacity: 0;
    visibility: hidden;
    display: none;
}

.faceContainer {
    margin-top: 10vh;
}

.saveBtn {
    text-align: center;
    
    &__p {
        // box-shadow: 0.025rem 0.025rem 0.025rem 0.025rem #93e9fa,
        //     0.025rem 0.025rem 0.025rem #ffffff;
        border: 1px solid rgba(255,255,255,0.7);
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);

        border-radius: 0.5rem;
        -moz-osx-font-smoothing: grayscale;
        backface-visibility: hidden;
        font-size: calc(1rem);
        // background-color: #a463f2;
        background: transparent;
        color: #fff;
        min-width: 5rem;
        max-width: 6rem;
        text-decoration: none;
        display: inline-block;
        text-transform: none;
        overflow: visible;
        font-family: inherit;
        line-height: 1.15;
        // margin-left: 1rem;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    
        &:hover {
            transform: translateY(1px);

            // box-shadow: 0.3rem 0.3rem 1rem #f6f8f8, -2px -2px 1rem #eefa43;
        }
    
        &:active {
            transform: translateY(0);
            box-shadow: 0rem 0rem 0rem 0rem #e9ec13,
                0rem 0rem 0rem #ffffff;
        }
    }
}

.modal-face {
    width: 50%;
    max-width: 200px;
    opacity: 0;
    position: absolute;
    left: 66%;
    top: 90%;

    &--inner {
        border: 2px solid white;
        background-color: rgb(2, 255, 65);
        height: 4rem;
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        border-radius: 10px;
    }
}
