// Set1
// COLORS
// $footer: rgb(94, 96, 64);
// $footer-light: rgb(140, 150, 80);
// $footer-dark: rgb(64, 67, 30);

// Color variables
// About page fonts
// $color-font-about: rgb(195, 201, 88); 
// About page cert fonts
// $color-font-cert: rgba(26, 136, 146); 
// Medium green
// $color-primary: rgb(234, 237, 192); 
// Light green
// $color-primary-light:  rgb(240, 240, 210);
// Dark green 
// $color-primary-dark: rgb(220, 210, 170); 

// Set2
// COLORS
// $footer: rgb(34, 80, 62);
// $footer-light: rgb(50, 100, 82);
// $footer-dark: rgb(24, 60, 52);

// Color variables
// $color-font-about: rgb(195, 201, 88);
// $color-font-cert: rgb(26, 136, 146); 
// $color-primary: rgb(227, 230, 215);
// $color-primary-light:  rgb(237, 240, 225); 
// $color-primary-light2: rgba(242, 245, 230);
// $color-primary-dark: rgb(220, 220, 213);



// Set 3 //
$color-gold: rgb(248, 227, 105);
$color-aqua: rgb(220,230,250);
$font: rgb(232,237,246);
$font-light: rgb(242, 242, 251);
$font-dark: rgb(212,217,226);

$footer: rgb(25, 40, 84);
$footer-light: rgb(45, 60, 104);
$footer-dark: rgb(20, 30, 64);

// Color variables
$color-font-about: rgb(195, 201, 88);
$color-font-cert: rgb(26, 136, 146);

$color-primary: rgb(157, 169, 225);
$color-primary-light:  rgb(177, 189, 235);
$color-primary-light2: rgb(187, 199, 240);
$color-primary-dark: rgb(137, 149, 205);

$color-secondary: rgb(69,88,167);
$color-secondary-light: rgb(89, 108, 187);
$color-secondary-dark: rgb(59, 78, 147);
$color-secondary-dark2: rgb(59, 78, 147);

$color-tertiary: rgb(157, 169, 225);
$color-tertiary-light: rgb(177, 189, 235);
$color-tertiary-dark: rgb(137, 149, 205);
// End- Set 3 //


///////
// $color-secondary-light: rgb(137,160,197);
// $color-secondary-dark: rgb(107,130,157);
// $color-secondary-dark2: rgba(87, 110, 137);

// $color-tertiary-light: #2998ff;
// $color-tertiary-dark: #5643fa;

// Font = $color-dark-grey
// Dark grey
$color-dark-grey: rgba(84, 87, 37, 1); 
// Font-light = $color-dark-grey-2
$color-dark-grey-2: rgba(104, 107, 50, 1);
$color-white:#fff; // White
$color-black: #000; // Black

$color-light-grey-1: #f7f7f7; // light-grey similar to white
$color-light-grey-2: #eee;

// Font size
$default-font-size: 1.6rem;

// Grid
$grid-width: 114rem;
$gutter-vertical: 1rem;
$gutter-horizontal: 5rem;