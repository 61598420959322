.App {
  // text-align: center;
  /* min-width: 75rem;
  min-height: 62.5rem; */
  position: relative;
  overflow: hidden;
}

@media (max-width: 375px) {
  .App {
    margin-top: 30rem;
    scale: 2;
  }
}

/*
.center {
  display: flex;
  justify-content: center;
}
*/

.particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* z-index: -1 = below anything on page */
  z-index: -1;
}
