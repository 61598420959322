@import '../../../sass/layout/register/form';

@media only screen 
and (min-width: 50px) {
    .form-box {
        @include box;

        &__label {
            // label($font-size)
            @include label(.875rem);
        }

        &__secondary {
            @include secondary;

            &--input {
                @include input(1rem);

                &:hover {
                    @include input-hover;
                }
            }
        }

        &__tertiary {
            // tertiary($width, $height)
            @include tertiary(2.5vh, 2.5vh);

            .icon {
                @include icon;
            }

            .icon-p-empty {
                @include icon-p-empty;
            }
        }
    }
}

@media only screen 
and (min-device-width: 320px) {
    .email-box {
        @include box;

        &__label {
            // label($font-size)
            @include label(.875rem);
        }

        &__secondary {
            @include secondary;

            &__input {
                @include input(1rem);

                &:hover {
                    @include input-hover;
                }
            }

            &__tertiary {
                // tertiary($width, $height)
                @include tertiary(2.5vh, 2.5vh);

                .icon {
                    @include icon;
                }

                .icon-p-empty {
                    @include icon-p-empty;
                }
            }
        }
    }
}





