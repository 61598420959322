.color-name {
    margin-top: 1rem;
}

.error-msg {
    margin-top: 1vh;
}

.color-page {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    padding-inline: 2% 2%;
}

.color-table {
    // width: 48vw;
    // padding-right: 2vw;
}

.color-table-image {
    object-fit: contain;
    min-width: 350px;
    max-width: 350px;
    aspect-ratio: 16 / 9;
    width: 30rem;
    height: 20rem;
}

.color {
    transition: all .15s ease-in;
    border-radius: 5px;
}
.color:hover {
    transform: translateY(-1.5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.raw-hex {
    max-width: 3.5rem;
    min-width: 3rem;
}

.w3c-color {
    transition: all .15s ease-in;
    border-radius: 5px;
}
.w3c-color:hover {
    transform: translateY(-1.5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.w3c-name {
    max-width: 6rem;
    min-width: 3rem;
}

.w3c-hex {
    min-width: 3rem;
    max-width: 3.5rem;
}

.raw-hex, .w3c-color, .w3c-name, .w3c-hex {
    transition: all .25s ease-in;
    border-radius: 5px;
}

.raw-hex:hover, .w3c-name:hover, .w3c-hex:hover {
    transform: translateY(-1.5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.raw-hex:active, .w3c-name:active, .w3c-hex:active {
    transform: translateY(-1px);
}



